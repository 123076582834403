import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
              <h1 className="coming-soon">Coming Soon</h1>
            <footer>
              <a href="https://x.com/tieroques" target="_blank" rel="noopener noreferrer">
                <h1>X</h1>
              </a>
            </footer>
        </div>
    );
}

export default App;
