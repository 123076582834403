/* global VANTA, THREE */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

document.addEventListener('DOMContentLoaded', () => {
    VANTA.FOG({
        el: "#root",
        THREE: THREE, // Use Three.js, passed from the outside
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        highlightColor: 0xffff01,
        midtoneColor: 0x775d68,
        lowlightColor: 0x000000,
        blurFactor: 0.34,
        speed: 0.70,
        zoom: 2.50
    });
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
